import { format, isValid } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Stage, AdminProfiles } from "src/types";

export function validateCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj.length !== 14) return false;

  if (/^(\d)\1+$/.test(cnpj)) return false;

  const validateDigits = (cnpj: string, factors: number[]): number => {
    let total = 0;
    for (let i = 0; i < cnpj.length; i++) {
      total += parseInt(cnpj[i]) * factors[i];
    }
    const remainder = total % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const factors = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  const firstCheck = validateDigits(cnpj.slice(0, 12), factors.slice(1));
  if (firstCheck !== parseInt(cnpj[12])) return false;

  const secondCheck = validateDigits(cnpj.slice(0, 13), factors);
  return secondCheck === parseInt(cnpj[13]);
}

export const profileToLabel: { [key in AdminProfiles["name"]]: string } = {
  consulting: "Pessoa consultora",
  manager: "Gestor(a) de carteira",
  admin: "Administrador",
};

export const SupersetIds = {
  production: [
    "6dc96405-ad8b-4bb0-a675-df0a1d27ea6a",
    "e9886d44-0103-41ec-93e0-5d8e58c0efb2",
    "3d7a78c5-b93a-4eba-be56-ed4392f97d48",
  ],

  development: [
    "aff4b399-c4e7-4bdb-abc7-f0cd51c8a06b",
    "aff4b399-c4e7-4bdb-abc7-f0cd51c8a06b",
    "aff4b399-c4e7-4bdb-abc7-f0cd51c8a06b",
  ],
};

export const ClientTypes = [
  {
    value: "Empresa",
    label: "Empresa",
  },
  {
    value: "Sistema S",
    label: "Sistema S",
  },
  {
    value: "Instituto",
    label: "Instituto",
  },
  {
    value: "Governo",
    label: "Governo",
  },
  {
    value: "ONG",
    label: "ONG",
  },
  {
    value: "Fundação",
    label: "Fundação",
  },
];

export const hypothesisAvaibleStatus = [
  { title: "A validar", bg: "#F0F3F4", color: "#161A1D" },
  { title: "Validando", bg: "#F0F3F4", color: "#161A1D" },
  { title: "Validada", bg: "#009927", color: "#FFFFFF" },
  { title: "Invalidada", bg: "#FFCCCC", color: "#161A1D" },
  { title: "Refinada", bg: "#009927", color: "#FFFFFF" },
];

export const testAvaibleStatus = [
  { title: "Em progresso", bg: "#F0F3F4", color: "#161A1D" },
  { title: "Validado", bg: "#009927", color: "#FFFFFF" },
  { title: "Invalidada", bg: "#FFCCCC", color: "#161A1D" },
];

export const ActionsStage = {
  Aquisição: [
    { label: "Aquisição - Primary action" },
    { label: "Aquisição - Secondary action" },
    { label: "Aquisição - Tertiary action" },
  ],
  Ativação: [
    { label: "Ativação - Primary action" },
    { label: "Ativação - Secondary action" },
    { label: "Ativação - Tertiary action" },
  ],
  Retenção: [
    { label: "Retenção - Primary action" },
    { label: "Retenção - Secondary action" },
    { label: "Retenção - Tertiary action" },
  ],
  Receita: [
    { label: "Receita - Primary action" },
    { label: "Receita - Secondary action" },
    { label: "Receita - Tertiary action" },
  ],
  Referência: [
    { label: "Referência - Primary action" },
    { label: "Referência - Secondary action" },
    { label: "Referência - Tertiary action" },
  ],
  Meta: [
    { label: "Vender para" },
    { label: "Meta - Secondary action" },
    { label: "Meta - Tertiary action" },
  ],
};

export const formatStagesByPriority = (stages: Stage[]): Stage[] => {
  let newStages = [];
  stages.forEach((stage) => {
    if (stage.priority !== 0) {
      newStages[stage.priority - 1] = stage;
    }
  });
  newStages.push(stages.find((stage) => stage.priority === 0) as Stage);
  return newStages;
};

export const columnsKanbanMaxLength = 20;
export const formatDate = (date: Date | undefined, customFormat?: string) => {
  if (date && isValid(date)) {
    return format(date, customFormat || "dd MMM yyyy", {
      locale: ptBR,
    });
  }
  return "";
};

export const createImageFromInitials = (props: {
  size: number;
  name: string;
  color: string;
  background: string;
  fontSize?: number;
}) => {
  const { background, name, size, color, fontSize } = props;
  if (name == null) return;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  if (context) {
    context.fillStyle = color;
    context.fillRect(0, 0, size, size);

    context.fillStyle = `${background}`;
    context.fillRect(0, 0, size, size);

    context.fillStyle = color;
    context.textBaseline = "middle";
    context.textAlign = "center";
    context.font = `${fontSize || "12"}px Lato, sans-serif`;
    context.fillText(name, size / 2, size / 2);
  }

  return canvas.toDataURL();
};

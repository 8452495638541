import {
  HStack,
  VStack,
  Image,
  Center,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import UserService from "src/services/user";
import { User } from "src/types";
import Icon from "../../../../components/base/Icon";
import Input from "../../../../components/base/Input/Index";
import { ProfileToLabel } from "../../common";
import { Props } from "./types";

export const ListLeftSide: React.FC<Props> = ({
  consultants,
  handleSelectConsultant,
  page,
  handleGetMore,
}) => {
  const theme = useTheme();
  const observer = useRef<any>(null);
  const [timer, setTimer] = useState<any>(null);
  const [selectedId, setSelectedId] = useState(consultants[0].id);
  const [searchMembers, setSearchMembers] = useState<{
    isLoading: boolean;
    data?: User[];
  }>();

  const handleSearchProject = async (filter: string) => {
    if (filter) {
      setSearchMembers({ isLoading: true, data: undefined });
      setSearchMembers({
        isLoading: false,
        data: await UserService.getAdminUsers({
          type: ["consulting", "manager"],
          filter,
        }),
      });
    } else {
      setSearchMembers(undefined);
    }
  };

  const handleChangeInput = (e: string) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => handleSearchProject(e), 500));
  };

  const lastItem = useCallback(
    (node: React.ReactNode) => {
      if (page.isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !page.isLast) {
          handleGetMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [handleGetMore, page]
  );

  return (
    <VStack maxW={"360px"} w={"100%"}>
      <Input
        placeholder={"Procurar membro"}
        width={"100%"}
        onChange={(e: { target: { value: string } }) =>
          handleChangeInput(e.target.value)
        }
        icon={"search"}
      />

      {searchMembers && searchMembers.isLoading ? (
        <Center flex={1} mt={"32px !important"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack
          w={"100%"}
          mt={"32px !important"}
          maxHeight={"600px"}
          overflow={"auto"}
        >
          {searchMembers?.data && searchMembers?.data?.length < 1 ? (
            <Text>Nenhum membro encontrado</Text>
          ) : (
            <>
              {(searchMembers?.data || consultants).map((consultant, key) => (
                <HStack
                  key={key}
                  w={"100%"}
                  background={
                    selectedId === consultant.id ? "Gray.$200" : "transparent"
                  }
                  borderRadius={"12px"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  position={"relative"}
                  transition={"300ms all"}
                >
                  <HStack
                    padding={"16px"}
                    flex={1}
                    onClick={() => {
                      setSelectedId(consultant.id);
                      handleSelectConsultant(consultant);
                    }}
                  >
                    <Center
                      width={"24px"}
                      height={"24px"}
                      borderRadius={"50%"}
                      background={"Gray.$300"}
                      mr={"12px !important"}
                    >
                      {consultant.image_url ? (
                        <Image
                          src={consultant.image_url}
                          borderRadius={"50%"}
                          objectFit={"cover"}
                          w={"100%"}
                          h={"100%"}
                        />
                      ) : (
                        <Icon name={"image"} color={theme.colors.Gray.$700} />
                      )}
                    </Center>
                    <VStack
                      alignItems={"flex-start"}
                      spacing={"0px !important"}
                      flex={1}
                    >
                      <Text
                        fontSize={"L_md"}
                        fontWeight={600}
                        color={"Gray.$800"}
                      >
                        {consultant.name} {consultant.surname}
                      </Text>
                      <Text
                        fontSize={"L_sm"}
                        fontWeight={400}
                        color={"Gray.$700"}
                      >
                        {ProfileToLabel[consultant.profile.name]}
                      </Text>
                    </VStack>
                  </HStack>
                </HStack>
              ))}
            </>
          )}

          {!searchMembers?.data && (
            <Center ref={lastItem as any} w={"100%"} minH={"40px"}>
              {page.isLoading && (
                <MutatingDots
                  height="100"
                  width="100"
                  color="#408EC5"
                  secondaryColor="#408EC5"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  visible={true}
                />
              )}
            </Center>
          )}
        </VStack>
      )}
    </VStack>
  );
};

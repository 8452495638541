import React, { useContext, useEffect } from "react";
import { ProjectContext } from "src/contexts/ProjectContext";
import SelectProject from "../SelectProject";
import { Center } from "@chakra-ui/react";
import { MutatingDots } from "react-loader-spinner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { KanbanContext } from "src/contexts/KanbanContext";

export type ProjectViewProps = {
  currentTabIn: number;
  setCurrentTabIn: (index: number) => void;
};

export const ProjectViewContext = React.createContext<ProjectViewProps>(
  {} as ProjectViewProps
);

interface Props {
  children: React.ReactNode;
}

const defaultTableParams = {
  index: "0",
};

const ProjectViewContextProvider = ({ children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams(defaultTableParams);
  const currentTabIn = searchParams.get("tab") || defaultTableParams.index;
  const { kanbanData } = useContext(KanbanContext);
  const { currentProject, selectProject } = useContext(ProjectContext);
  const { projectId } = useParams();
  const navigation = useNavigate();

  useEffect(() => {
    if (projectId) {
      selectProject(projectId);
      navigation(`/${projectId}?tab=${currentTabIn}`);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!!kanbanData.data) {
      setSearchParams({ tab: "1" });

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanData.data]);

  return (
    <ProjectViewContext.Provider
      value={{
        currentTabIn: parseInt(currentTabIn),
        setCurrentTabIn: (index) => setSearchParams({ tab: index.toString() }),
      }}
    >
      {currentProject.isLoading && !!currentProject.project_id ? (
        <Center w={"100%"} h={"100%"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>{!currentProject.data ? <SelectProject /> : children}</>
      )}
    </ProjectViewContext.Provider>
  );
};

export const useProjectViewContext = () => {
  const context = useContext(ProjectViewContext);

  if (context) return context;

  throw new Error(
    "useProjectContext must be used within a ProjectViewContextProvider."
  );
};

export default ProjectViewContextProvider;
